import React from "react"
import styled from "styled-components";

import ContainerSmall from "../styles/ContainerSmall";




const GenericTextWrapper = styled.div`
  background-color: ${props => props.theme.blue};
  padding-top: 70px;
  padding-bottom: 70px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;


  h2 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: ${props => props.theme.fontXl};
    color: #fff;
    text-transform: uppercase;
  }

  p {
    color: #fff;
    font-size: ${props => props.theme.fontMdSm};

    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: #fff;
  }
`

const GenericText = (props) => {


  return (
    <GenericTextWrapper>
      <ContainerSmall>
        <h2>{props.title}</h2>
        <div dangerouslySetInnerHTML={{__html: props.text}} />
      </ContainerSmall>
    </GenericTextWrapper>
  )
}

export default GenericText;
