import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TextPageHeader from "../components/TextPageHeader";
import Step from "../components/Step";
import CaseStudyQuote from "../components/CaseStudyQuote";
import GenericText from "../components/GenericText";


export default function Start(props) {
  return (
  <Layout location={props.location} grayFooter={true} >

    <CaseStudyQuote
        source='Jason Snider'
        stafftitle='The associate dean for academic services and registrar, Georgetown College'
        quote='The process of switching from our previous catalog software to Clean Catalog was incredibly easy! One of the easiest on-boarding experiences we’ve had with a third party vendor.'
    />


    <TextPageHeader
      title={'Getting Started is Simple and Fast'}
      text={'Review the outline of our onboarding process.'}
    />
    <Step
      number={1}
      title={'Contact our team.'}
      image={'step-1.png'}
      text={'Email, message or pick up the phone and talk to us. We’re all ears as you tell us what pain points you’re looking to solve. We’ll brainstorm creative solutions and ways to tailor our software for you. This is just an exploration call and a time for questions. No commitment needed.'}
      buttonText={'Let\'s Talk'}
      buttonPath={'/contact/'}
    />
    <Step
      number={2}
      title={'Importing: Send your documents – we’ll make them digital'}
      image={'step-2.png'}
      text={'Your catalogs are now on their way to being transformed. Yes, really. We take care of all content migration and data entry for you, and review all imported content for accuracy. It’s the best of both worlds – automation to speed up launch timelines, plus extra eyeballs to catch any issues.'}

    />
    <Step
      number={3}
      title={'Fine tuning: revised & adjusted as needed'}
      image={'step-3.png'}
      text={'If you want your catalog up right away, we can launch it with minimal users and workflows, and then build those out after launch. We\'ll work with you to figure out the best system for your college for editing the catalog and building next year\'s catalog, and we\'ll build out the appropriate user accounts and workflow.'}

    />
    <Step
      number={4}
      title={'Foundations for success: Users & workflows'}
      image={'step-4.png'}
      text={'You have different options for making your catalog available. If you want “as soon as possible”, we can make that happen. We’ll launch with the basic users and workflows you need, and then expand post-launch. Our team will advise you on some best practices to lay the foundations for next year’s catalog too, setting your staff up for success. \n' +
        'We’ll also lead a staff tutorial to ensure your team feels confident managing your new catalog post-launch. Your users will be added, granted the appropriate permissions, and tied to relevant workflows. All that’s left is to hit the ground running!'}
    />

    <Step
      number={5}
      title={'Ongoing support & contact'}
      text={'As your partner, we stay in touch after you’ve gone “live”. From advice and answers, to giving tried-and-tested feedback for making things even better. You’re guaranteed to talk with a live human from our US-based team, every single time.'}
      image={'step-5.png'}
    />
    <GenericText
    title={'Take the first step: Transform your catalog today'}
    text={'<p>Schedule a no-obligation demo, get a customized quote, or just pick our brains about the process. We’re here to help.</p>' +
    '<p><a href="tel:1-800-648-7146">1-800-648-7146</a><br/>' +
    '<a href="mailto:info@cleancatalog.com">info@cleancatalog.com</a> <br/>' +
    '1752 NW Market Street #4305 <br/>' +
    'Seattle, WA 98107</p>'
    }
    />





  </Layout>
  )
}

export function Head(props) {
  return (
    <Seo
      title="Start With Clean Catalog"
      description={'How to migrate your course catalog to a modern platform.'}
      canonical={props.location.pathname}
    />
  )
}

