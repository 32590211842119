import React from "react"
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Button from "./Button";


const StepWrapper = styled.div`
  max-width: 874px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    grid-template-columns: 1fr;
  }
`;


const StepText = styled.div`
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${props => props.number%2 ? "left" : "right"};
  h2 {
  font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 110%;

    /* or 31px */
    letter-spacing: 0.03em;

    /* Primary/Black */
    color: #141414;
    margin-bottom: 10px;
    margin-top: 0;

  }
  p {
    margin: 0;
  }
  a {
    margin-top: 30px;
  }
`;

const StyledNumber = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 150%;
  color: #D74339;
  margin-bottom: 30px;
  position: relative;
  @media(min-width: ${props => props.theme.screenSm}) {
  &:before {
    content: '';
    position: absolute;
    left: ${props => props.number % 2 ? "-110px" : "auto"};
    right: ${props => props.number % 2 ? "auto" : "-110px"};
    z-index: 5;
    top: 50%;
    width: 100px;
    height: 2px;
    display: block;
    background: #D74339;
  }
  }
  `

const Step = (props) => {

  const data = useStaticQuery(graphql`{
  allFile(filter: {absolutePath: {regex: "/steps/"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(width: 427, layout: CONSTRAINED)
      }
      name
    }
  }
}
`);




  // Filter to just our image
  // Filter to just our current image, passed down in props

  let src = data.allFile.nodes.filter((item) => {
    return props.image === item.name + '.png';
  });


  let image = null;

  let imgSrc = src.filter((item) => {
    return item.node !== null && item.childImageSharp.gatsbyImageData !== null;
  });


  if(imgSrc !== null && imgSrc.length > 0) {
    image = <GatsbyImage image={getImage(imgSrc[0])} alt={props.title} />;
  }


  // Determine if image should be on right or left
  let leftImage = null;
  let rightImage = null;
  if(props.number % 2) {
    leftImage = image;
  } else {
    rightImage = image;
  }

  // The magic of Javascript types.
  let number = '0' + props.number;

  let button;
  if(props.buttonText && props.buttonPath) {
    button = <Button path={props.buttonPath} text={props.buttonText} width={'12rem'}/>
  }


  return (
    <StepWrapper>
      {leftImage}
      <StepText number={props.number}>
        <StyledNumber number={props.number}>{number}</StyledNumber>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
        {button}
      </StepText>
      {rightImage}
    </StepWrapper>
  )
}

export default Step;
